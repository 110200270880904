
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Stock Summary</h5>
      <div class="row gy-2 justify-content-between">
        <div class="col-lg-5 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li v-for="(sortOption, opIndex) in sortOrderOptions" :key="opIndex">
                <button  @click.prevent="setSortOrder(sortOption.value)" class="dropdown-item" type="button">
                  {{ sortOption.label }}
                </button>
              </li>
            </ul>
          </div>
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="my-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm" align="middle">
              <template v-slot:cell(medicine)="data">
                <div style="min-width:15rem;" class="">
                  <strong>{{ data.item.name }} </strong> - <span v-if="data.item.type_title"> 
                    {{data.item.type_title}}
                    </span>
                  <br />
                  <small>
                    {{data.item.description}}
                  </small>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "hospital-medicine-stock",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
      searchQuery:"",
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "medicine",
          label: "Medicine",
        },
        {
          key: "available_quantity",
          label: "Available Quantity",
        },
        {
          key: "total_allocated_quantity",
          label: "Allocated Quantity",
        },
        {
          key: "total_confirmed_quantity",
          label: "Confirmed Quantity",
        },
        {
          key: "total_pending_quantity",
          label: "Pending Quantity",
        },
        {
          key: "total_prescribed_quantity",
          label: "Prescribed Quantity",
        },
        {
          key: "prescribed_drugs_count",
          label: "Prescription count",
        },
        {
          key: "allocated_items_count",
          label: "Allocation Count",
        },
      ],
      pageData:{
        data: []
      },
      sortOrder: "available_quantity/desc",
      sortOrderOptions:[
        { value: 'available_quantity/desc', label: "Available Quantity (Desc)"},
        { value: 'available_quantity/asc', label: "Available Quantity (Asc)"},
      ]
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0';
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
    getSortOrder(){
      return {sort_column: this.sortOrder.split("/")[0], sort_direction: this.sortOrder.split("/")[1]}
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = { page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.getSortOrder
      }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/medicines/stock?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
        })
    },
    setSortOrder(order){
      this.sortOrder = order;
      this.fetchItems()
    },
    initializeAll(){
    }
  },
  created() {
    this.fetchItems()
  }
}

</script>

